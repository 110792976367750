<template>
  <div id="ib_report">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="content_title">
          <h2>{{ $t('menu.ibReport') }}</h2>

          <p></p>
        </div>

        <div class="query">
          <!-- 提交部分 -->

          <div class="query_content_main clearfix">
            <div class="query_content_main_left">
              <el-form>
                <AccountNumber @accountCallback="queryIbReportData"></AccountNumber>
              </el-form>

              <DateRangePicker v-bind:startDate.sync="startDate" v-bind:endDate.sync="endDate"></DateRangePicker>
            </div>

            <div class="query_content_main_right">
              <loading-button
                class="purple_button"
                :callback="queryIbReportData"
                text="common.keys.UPDATE"
              ></loading-button>
              <el-button class="purple_button" @click="download" :disabled="!customerDataPermission">{{
                $t('common.button.download')
              }}</el-button>
            </div>
          </div>

          <!-- 4个盒子 -->

          <div class="calendar_box">
            <ul>
              <li v-on:click="selectedCard = 'netFunding'" data-testid="netFunding">
                <div class="left netFunding">
                  <img src="@/assets/uploads/icons-big-trade-markets@3x.png" />
                </div>

                <div class="right">
                  <div>
                    <p v-if="customerDataPermission">
                      {{ currAccountCurrencyFilter }} {{ cardData.netFunding.value | currency('') }}
                    </p>
                    <p v-else>***</p>
                    <span>{{ $t('ibReport.netFunding') }}</span>
                  </div>
                </div>
              </li>

              <li v-on:click="selectedCard = 'deposits'" data-testid="deposits">
                <div class="left deposits">
                  <img src="@/assets/uploads/icons-big-funding@3x.png" />
                </div>

                <div class="right">
                  <div>
                    <p v-if="customerDataPermission">
                      {{ currAccountCurrencyFilter }} {{ cardData.deposits.value | currency('') }}
                    </p>
                    <p v-else>***</p>
                    <span>{{ $t('ibReport.deposits') }}</span>
                    <img src="@/assets/uploads/icons-stock-up@3x.png" alt />
                  </div>
                </div>
              </li>

              <li v-on:click="selectedCard = 'withdraw'" data-testid="withdraw">
                <div class="left withdraw">
                  <img src="@/assets/uploads/icons-big-low-cost@3x.png" />
                </div>

                <div class="right">
                  <div>
                    <p v-if="customerDataPermission">
                      {{ currAccountCurrencyFilter }} {{ cardData.withdraw.value | currency('') }}
                    </p>
                    <p v-else>***</p>
                    <span>{{ $t('ibReport.withdraw') }}</span>
                    <img src="@/assets/uploads/icons-stock-down@3x.png" alt />
                  </div>
                </div>
              </li>

              <li v-on:click="selectedCard = 'openedAccounts'" data-testid="openedAccounts">
                <div class="left openAccounts">
                  <img src="@/assets/uploads/icons-big-client-portal@2x.png" />
                </div>

                <div class="right">
                  <div>
                    <p v-if="customerDataPermission">{{ cardData.openedAccounts.value | currency('', 0) }}</p>
                    <p v-else>***</p>
                    <span>{{ $t('ibReport.openedAccs') }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- 表格部分 -->

        <div class="content clearfix">
          <!-- 1. net Funding 
            2.deposits 
          3.withdraw-->

          <div class="content_box" v-if="['netFunding', 'deposits', 'withdraw'].indexOf(selectedCard) >= 0">
            <div class="select">
              <el-select v-model="timeType" @change="handleTimeTypeChange" class="time-type" data-testid="timeType">
                <el-option
                  v-for="item in timeTypeList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.value"
                ></el-option>
              </el-select>
              <p>
                {{ $t('ibReport.funding') }} {{ $t('common.keys.FROM') }} {{ startDate | formatDate('DD/MM/YYYY') }}
                {{ $t('common.keys.TO') }} {{ endDate | formatDate('DD/MM/YYYY') }}
              </p>
            </div>

            <div class="table_box">
              <el-table :data="fundingDisplayData" style="width: 100%">
                <el-table-column :label="$t('common.keys.DATE')" min-width="120">
                  <template slot-scope="scope">
                    <span>{{ scope.row.date | formatDate('DD/MM/YYYY') }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('ibReport.deposits')" min-width="120">
                  <template slot-scope="scope">
                    <span>{{ currAccountCurrencyFilter }}{{ scope.row.deposit | currency('') }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('ibReport.withdraw')" min-width="120">
                  <template slot-scope="scope">
                    <span>{{ currAccountCurrencyFilter }}{{ scope.row.withdraw | currency('') }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('ibReport.netFunding')" min-width="120">
                  <template slot-scope="scope">
                    <span>{{ currAccountCurrencyFilter }}{{ scope.row.net | currency('') }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <!-- 分页 -->

            <pagination v-bind:table-data="fundingTableData" v-bind:display-data.sync="fundingDisplayData"></pagination>
          </div>

          <!-- 1. opened Accounts -->

          <div class="content_box" v-if="selectedCard == 'openedAccounts'">
            <div class="select">
              <p>
                {{ $t('ibReport.openedAccs') }} {{ $t('common.keys.FROM') }} {{ startDate | formatDate('DD/MM/YYYY') }}
                {{ $t('common.keys.TO') }} {{ endDate | formatDate('DD/MM/YYYY') }}
              </p>
            </div>

            <div class="table_box openedAccounts">
              <el-table :data="openedAccountsDisplayData" style="width: 100%" @sort-change="sortChange">
                <el-table-column :label="$t('common.keys.DATE')" min-width="120">
                  <template slot-scope="scope">
                    <span>{{ scope.row.approvedDate | date('DD/MM/YYYY') }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('common.keys.ACCNUM')" min-width="120">
                  <template slot-scope="scope">
                    <span>{{ scope.row.mt4Account }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('common.keys.NAME')" min-width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.name }}</span>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('common.keys.EMAIL')" min-width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.email }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('common.keys.ACCTYPE')"
                  sortable
                  sort-by="type"
                  :min-width="170"
                  :sort-orders="['ascending', 'descending']"
                >
                  <template slot-scope="scope">
                    <span> {{ $config.accountTypeMaps[scope.row.type] }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="platform"
                  :label="$t('common.keys.PLATFORM')"
                  sortable
                  sort-by="platform"
                  :min-width="120"
                  :sort-orders="['ascending', 'descending']"
                ></el-table-column>

                <el-table-column
                  prop="currency"
                  :label="$t('common.keys.BASECURRENCY')"
                  sortable
                  sort-by="currency"
                  :min-width="170"
                  :sort-orders="['ascending', 'descending']"
                ></el-table-column>

                <el-table-column
                  :label="$t('common.keys.BALANCE')"
                  sortable
                  sort-by="balance"
                  :min-width="120"
                  :sort-orders="['ascending', 'descending']"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.currency | currencySymbol }}{{ scope.row.balance | currency('') }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <pagination
              v-bind:table-data="openedAccountsTableData"
              v-bind:display-data.sync="openedAccountsDisplayData"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import DateRangePicker from '@/components/DateRangePicker';
import LoadingButton from '@/components/LoadingButton';
import AccountNumber from '@/components/form/AccountNumber';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import { apiQueryIbReportData } from '@/resource';
import { defaultVariableMapping } from '@/components/profile/SubProfile.js';

export default {
  components: { Pagination, DateRangePicker, LoadingButton, AccountNumber },
  data() {
    return {
      userID: this.$store.state.common.CUID,
      startDate: '',
      endDate: '',
      cardData: {
        netFunding: { label: 'NET FUNDING', value: 0 },
        deposits: { label: 'DEPOSITS', value: 0 },
        withdraw: { label: 'WITHDRAW', value: 0 },
        openedAccounts: { label: 'OPENED ACCOUNTS', value: 0 }
      },
      selectedCard: '',
      fundingTableData: [],
      fundingDisplayData: [],
      openedAccountsTableData: [],
      openedAccountsDisplayData: [],
      currAccountCurrencyFilter: '',
      updateTimeRecord: { startDate: this.startDate, endDate: this.endDate },
      timeTypeList: [
        // BE field name: last_update_time, FE display name: Processed Time
        { label: this.$t('common.keys.lastUpdateTime'), value: 2 },
        // BE field name: create_time, FE display name: Submission Time
        { label: this.$t('common.keys.createTime'), value: 1 }
      ],
      timeType: 2
    };
  },
  methods: {
    download() {
      if (!this.fundingTableData.length) {
        this.$message({
          message: this.$t('report.nodataMessage'),
          type: 'warning'
        });
        return Promise.resolve();
      }

      let days = moment(this.endDate).diff(moment(this.startDate), 'days');

      const MAX_DAYS = 90;
      const fileName = `ib_report_${moment(this.updateTimeRecord.startDate).format('YYYY-MM-DD')}_${moment(
        this.updateTimeRecord.endDate
      ).format('YYYY-MM-DD')}_${moment().format('YYYY-MM-DD HH:mm:ss A')}`;
      if (days > MAX_DAYS) {
        this.$message({
          message: this.$t('report.timeLimitMessage'),
          type: 'warning'
        });
        return Promise.resolve();
      }

      const fundReportHeader = [
        'common.keys.DATE',
        'home.Account',
        'home.DEPOSITS',
        'home.WITHDRAW',
        'home.NET_FUNDING'
      ].map(key => this.$t(key));

      const openedAccountHeader = [
        'common.keys.DATE',
        'home.Account',
        'home.Name',
        'common.keys.EMAIL',
        'common.keys.ACCTYPE',
        'common.keys.PLATFORM',
        'common.keys.BASECURRENCY',
        'home.availableBalance'
      ].map(key => this.$t(key));

      let option = {
        fileName,
        datas: [
          {
            sheetData: this.fundingTableData,
            sheetName: 'Fund Report',
            sheetHeader: fundReportHeader,
            columnWidths: [5, 5, 6, 6, 7]
          },
          {
            sheetData: this.parseExcelData(this.openedAccountsTableData),
            sheetName: 'Opened Accounts',
            sheetHeader: openedAccountHeader,
            columnWidths: [5, 6, 7, 8, 8, 5, 6, 5]
          }
        ]
      };
      const toExcel = new ExportJsonExcel(option);
      toExcel.saveExcel();
      return Promise.resolve();
    },
    parseExcelData(openedAccountsTableData) {
      return openedAccountsTableData.map(item => {
        const { approvedDate, name, platform, mt4Account, currency, balance, type, email } = item;
        return {
          approvedDate: moment(approvedDate).format('YYYY-MM-DD'),
          mt4Account,
          name,
          email,
          type: this.$config.accountTypeMaps[type],
          platform,
          currency,
          balance
        };
      });
    },
    handleTimeTypeChange() {
      this.queryIbReportData();
    },
    queryIbReportData() {
      if (this.accountID === null || this.accountID.length < 1) {
        return Promise.resolve();
      }

      return apiQueryIbReportData({
        userId: this.userID,
        rebateAccount: this.accountID,
        startDate: this.startDate,
        endDate: this.endDate,
        timeType: this.timeType
      }).then(resp => {
        if (resp.data.code == 305) {
          this.cardData.netFunding.value = 0;
          this.cardData.deposits.value = 0;
          this.cardData.withdraw.value = 0;
          this.cardData.openedAccounts.value = 0;
          this.fundingTableData = [];
          this.openedAccountsTableData = [];
          this.currAccountCurrencyFilter = '';
        } else {
          const respData = resp.data;
          this.cardData.netFunding.value = respData.netFunding;
          this.cardData.deposits.value = respData.totalDeposit;
          this.cardData.withdraw.value = respData.totalWithdraw;
          this.cardData.openedAccounts.value = respData.openedAccounts;

          this.fundingTableData = respData.funding;
          this.openedAccountsTableData = respData.openedAccountsDetail;

          this.currAccountCurrencyFilter = this.$options.filters.currencySymbol(this.currAccountCurrency);
        }
      }, this.$handleError);
    },
    sortData() {
      this.sorting.column = this.sorting.column || 'balance';
      this.sorting.order = this.sorting.order || 'descending';
      this.openedAccountsTableData.sort((a1, a2) => {
        return this.sorting.order == 'descending'
          ? a2[this.sorting.column] - a1[this.sorting.column]
          : a1[this.sorting.column] - a2[this.sorting.column];
      });
    },
    sortChange(column, prop, order) {
      if (!column.column) {
        return;
      }
      this.sorting = { column: column.column.sortBy, order: column.order };
      this.sortData();
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        this.selectedCard = query.cardMethod || 'netFunding';
      },
      immediate: true
    }
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    currAccountCurrency() {
      return this.$store.state.behavior.CUR;
    },
    customerDataPermission() {
      return this.$store.state.common.customerDataPermission;
    },
    timeTypeDefault() {
      return this.timeType === 1 ? 'createTime' : 'updateTime';
    },
    downloadIbHeader() {
      return this.timeType === 1 ? this.$t('common.keys.createTime') : this.$t('common.keys.lastUpdateTime');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/ibReport.scss';
</style>
