import { render, staticRenderFns } from "./IbReport.vue?vue&type=template&id=fc9516e6&scoped=true&"
import script from "./IbReport.vue?vue&type=script&lang=js&"
export * from "./IbReport.vue?vue&type=script&lang=js&"
import style0 from "./IbReport.vue?vue&type=style&index=0&id=fc9516e6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc9516e6",
  null
  
)

export default component.exports